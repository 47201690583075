import { useState } from "react"

import { MTextField, TMTextFieldProps } from "src/ui/MTextField/MTextField"

import {
  ISettingContainerBaseProps,
  SettingContainer,
  TSettingContainerOnSaveReturnType,
} from "./SettingContainer"

export function SettingNumber({
  title,
  description,
  value,
  min,
  minRecommended,
  max,
  maxRecommended,
  onSave,
  errorHelperText,
  textFieldProps,
  ...props
}: ISettingContainerBaseProps & {
  textFieldProps?: TMTextFieldProps
  onSave(newVal: number): TSettingContainerOnSaveReturnType
  value: number
  min: number // make optional
  minRecommended?: number
  max: number // make optional
  maxRecommended?: number
  displayValueSuffix?: string
  errorHelperText: (type: TNumberError) => string | null
}) {
  const [saveValue, setSaveValue] = useState(value)
  const { error, errorType } = validate({
    value: saveValue,
    min,
    max,
    minRecommended,
    maxRecommended,
  })

  return (
    <SettingContainer
      title={title}
      description={description}
      onSave={() => {
        return onSave(saveValue)
      }}
      displayValue={`${value} ${props.displayValueSuffix || ""}`}
      hasError={error}
      InputComponent={({ saving }) => (
        <Input
          valueInitial={value}
          setSaveValue={setSaveValue}
          disabled={saving}
          error={error}
          helperText={errorHelperText(errorType)}
          min={min}
          max={max}
          {...textFieldProps}
        />
      )}
      {...props}
    />
  )
}

function Input({
  valueInitial,
  setSaveValue,
  ...textFieldProps
}: {
  valueInitial: number
  setSaveValue(newVal: number): void
} & TMTextFieldProps) {
  const [_value, _setValue] = useState(valueInitial)
  return (
    <MTextField
      type={"number"}
      value={String(_value)}
      onChange={(value) => {
        _setValue(Number(value))
        setSaveValue(Number(value))
      }}
      {...textFieldProps}
    />
  )
}

function validate({
  value,
  min,
  max,
  minRecommended,
  maxRecommended,
}: {
  value: number | null
  min: number
  max: number
  minRecommended?: number
  maxRecommended?: number
}): { error: boolean; errorType: TNumberError } {
  let error = false
  let errorType: TNumberError = null

  if (!value && value !== 0) {
    error = true
    errorType = "invalid"
  } else if (value < min) {
    error = true
    errorType = "below"
  } else if (value > max) {
    error = true
    errorType = "above"
  } else if (value >= min && value < Number(minRecommended)) {
    errorType = "belowSoft"
  } else if (value <= max && value > Number(maxRecommended)) {
    errorType = "aboveSoft"
  }
  return { error, errorType }
}

export type TNumberError =
  | "below"
  | "belowSoft"
  | "above"
  | "aboveSoft"
  | "invalid"
  | null
